<template>
  <div class="applyAccess">
    <!-- 头部 + banner -->
    <div class="header">
      <div class="flex_between header_top">
        <div class="applyAccess_logo" @click="$router.replace({path: 'openPlatform'})">
          <img src="../../../static/img/openPlatform/logo.png">
        </div>
        <div class="flex_between header_nav">
          <div @click="$router.replace({path: 'openPlatform'})">首页</div>
          <!-- <div>接口文档</div> -->
          <div class="action_nav">申请接入</div>
          <!-- <div>管理后台</div> -->
        </div>
      </div>
    </div>
    <!-- body -->
    <div class="applyAccess_content content_box box_shadow">
      <div class="applyAccess_body">
        <div class="applyAccess_body_title">
          <div>欢迎接入莲藕健康开发平台</div>
          <!-- <div class="applyAccess_body_login"><span>已有开放平台？</span><span class="login_btn">直接登录</span></div> -->
        </div>
        <el-form
          ref="ruleForm"
          v-loading="loading"
          :model="ruleForm"
          :rules="rules"
          label-width="88px"
          class="demo-ruleForm"
        >
          <el-form-item label="接入系统:" prop="type">
            <el-checkbox-group v-model="ruleForm.type">
              <el-checkbox label="微信H5" name="type" />
              <el-checkbox label="微信小程序" name="type" />
              <el-checkbox label="APP" name="type" />
              <el-checkbox label="PC网页" name="type" />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="平台名称:" prop="platformName">
            <el-input v-model="ruleForm.platformName" />
          </el-form-item>
          <el-form-item label="公司名称:" prop="companyName">
            <el-input v-model="ruleForm.companyName" />
          </el-form-item>
          <el-form-item label="您的姓名:" prop="name">
            <el-input v-model="ruleForm.name" />
          </el-form-item>
          <el-form-item label="您的手机:" prop="phone">
            <el-input v-model="ruleForm.phone" maxlength="11" />
          </el-form-item>
          <el-form-item label="申请用途:" prop="application">
            <el-input v-model="ruleForm.application" />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: calc(100% - 18px);"
              @click="submitForm('ruleForm')"
            >提交</el-button>
            <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      loading: false,
      going: false,
      aegin: false,
      ruleForm: {
        type: [],
        platformName: '',
        companyName: '',
        name: '',
        phone: '',
        application: ''
      },
      rules: {
        type: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一个接入系统',
            trigger: 'change'
          }
        ],
        platformName: [
          { required: true, message: '请输入平台名称', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
          }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
          }
        ],
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: '请输入您的手机', trigger: 'blur' },
          { required: true, message: '手机号格式不正确', trigger: 'blur', pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/ }
        ],
        application: [
          { required: true, message: '请输入申请用途', trigger: 'blur' },
          {
            min: 1,
            max: 200,
            message: '长度在 1 到 200 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      if (this.going) { if (!this.aegin) { this.aegin = true; ElMessage.error('请勿重复操作') } return }
      this.going = true
      setTimeout(() => {
        this.going = false
        this.aegin = false
      }, 3000)
      this.loading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.ruleForm)
          const { type, platformName, companyName, name, phone, application } = this.ruleForm
          try {
            this.$api.GetMessageToken({}).then(res => {
              this.$api.AddOfficialWebsiteMessage({
                CooperationMode: '开放平台接入申请',
                CooperationType: '开放平台业务',
                MessageJson: {
                  '接入系统': type.join(','),
                  '平台名称': platformName,
                  '公司名称': companyName,
                  '姓名': name,
                  '手机': phone,
                  '申请用途': application
                },
                Token: res.data
              }).then(({ code, msg }) => {
                this.loading = false
                if (code) {
                  ElMessage.success('提交成功!')
                  this.$refs[formName].resetFields()
                } else ElMessage.error(msg)
              })
            })
          } catch (error) {
            this.loading = false
            console.log(error)
          }
        } else {
          this.loading = false
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss">
body {
  font-size: 16px;
  letter-spacing: 1.92px;
  line-height: 1.25;
}
.fontSize20 {
  font-size: 20px;
}
.applyAccess {
  text-align: left;
  background: #F5F7FA;
  min-height: 100vh;
  min-width: 1200px;
}
.content_box {
  width: 1200px;
  margin: 0 auto;
}
.box_shadow {
  background: #FFFFFF;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.applyAccess {
  .header {
    background: #363547;
    .header_top {
      // max-width: 1200px;
      height: 50px;
      padding: 0 30px;
    }
    .applyAccess_logo {
      height: 28px;
      cursor: pointer;
      >img {
        height: 100%;
      }
    }
    .header_nav {
      color: #A8ABB3;
      letter-spacing: 0;
      .action_nav {
        color: #ffffff;
        font-weight: 600;
      }
      >div {
        cursor: pointer;
        margin-right: 70px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
  .applyAccess_content {
    margin-top: 100px;
    padding-bottom: 90px;
    .applyAccess_body {
      .applyAccess_body_title {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        position: relative;
        padding: 80px 0;
        .applyAccess_body_login {
          font-size: 16px;
          right: 151px;
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
          color: #A8ABB3;
          .login_btn {
            color: #6E67F0;
          }
        }
      }
      .demo-ruleForm {
        padding: 0 316px;
        .el-form-item__label {
          color: #000000;
          font-weight: bold;
          font-size: 18px;
          padding: 0 10px 0 0;
          letter-spacing: 0;
        }
        .el-form-item__content {
          height: 40px;
        }
        .el-button--primary {
          background: #6E67F0;
        }
        .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
          content: '';
          margin: 0;
        }
        .el-form-item.is-required:not(.is-no-asterisk):after,.el-form-item.is-required:not(.is-no-asterisk):after {
          content: "*";
          color: var(--el-color-danger);
          margin-left: 10px;
          font-size: 18px;
          font-weight: 600;
          line-height: 50px;
          height: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .applyAccess .applyAccess_content {
    margin-top: 50px;
    padding-bottom: 45px;
  }
  .applyAccess .applyAccess_content .applyAccess_body .applyAccess_body_title {
    padding: 40px 0;
  }
}
</style>
